import React, { useState, useRef, forwardRef } from 'react';
import { MaterialSymbol } from 'react-material-symbols';

const InputProfilePhoto = forwardRef(({ imageSrc }, ref) => {
  const [image, setImage] = useState(imageSrc || '');
  const fileInputRef = useRef(null);

  // A referência externa ref será usada como referência interna se fornecida
  const combinedRef = ref || fileInputRef;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    combinedRef.current.click();
  };

  return (
    <div 
      className={`group w-xxxl h-xxxl rounded-full overflow-hidden relative cursor-pointer bg-yellow-1`} 
      onClick={handleClick}
    >
      {image && (
        <img 
          src={image} 
          className="w-full h-full absolute top-0 left-0 object-cover" 
        />
      )}
      <div className={`w-full h-full absolute top-0 left-0 flex items-center justify-center ${image ? 'group-hover:bg-black-1 group-hover:bg-opacity-50 group-hover:flex hidden' : ''}`}>
        <MaterialSymbol
          icon='add_a_photo'
          size={24}
          grade={-25}
          color={image ? '#FBFCF8' : '#B27C1F'}
        />
      </div>
      <input
        type="file"
        accept="image/*"
        ref={combinedRef}
        className="hidden"
        onChange={handleFileChange}
      />
    </div>
  );
});

export default InputProfilePhoto;
