import React, { useRef, useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Body from '../typography/Body';
import { MaterialSymbol } from 'react-material-symbols';

const InputNumber = ({ 
    name, 
    label, 
    placeholder, 
    value: propValue, 
    autoComplete, 
    onChangeText: propOnChangeText, 
    icon, 
    mask,
    sanitizeValue = value => value.replace(/[^0-9.-]/g, '') // Função padrão de sanitização
}) => {
  const [localValue, setLocalValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const textInputRef = useRef(null);

  const handlePress = () => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  };

  const handleChangeText = (event) => {
    const rawValue = event.target.value;
    const sanitizedValue = sanitizeValue(rawValue); // Sanitiza de acordo com a função fornecida
    
    // Atualiza o valor localmente e chama o propOnChangeText com o valor sanitizado
    setLocalValue(rawValue); // Mantenha o valor com a máscara no input
    if (propOnChangeText) {
      propOnChangeText(sanitizedValue); // Passa o valor sem a máscara para a função externa
    }
  };

  const value = propValue !== undefined ? propValue : localValue;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (textInputRef.current && !textInputRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`flex flex-col bg-green-1 px-sm rounded-xs border justify-center h-xl w-full cursor-text ${isFocused ? 'border-green-3' : 'border-green-1'}`}
      onClick={handlePress}
    >
      <div className="flex flex-row items-center gap-xxs select-none">
        <MaterialSymbol
          icon={icon}
          size={value || isFocused ? 12 : 16}
          grade={-25}
          color={isFocused ? '#799537' : '#A3A799'}
        />
        <Body size="lg" style={`${value ? '!text-[10.24px] h-[12px]' : 'text-gray-3 h-[16px]'} ${isFocused ? 'text-green-3 !text-[10.24px] !h-[12px]' : 'text-gray-3'}`}>
          {label}
        </Body>
      </div>
      {mask ? (
        <InputMask
          mask={mask}
          maskChar={null}
          value={value}
          onChange={handleChangeText}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              type="text"
              name={name}
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={`leading-[125%] text-[16px] text-black-1 focus:outline-none focus-visible:outline-none bg-transparent ${isFocused || value ? 'h-[24px]' : 'h-0'}`}
              ref={textInputRef}
            />
          )}
        </InputMask>
      ) : (
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChangeText}
          autoComplete={autoComplete}
          className={`leading-[125%] text-[16px] text-black-1 focus:outline-none focus-visible:outline-none bg-transparent ${isFocused || value ? 'h-[24px]' : 'h-0'}`}
          ref={textInputRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}
    </div>
  );
};

export default InputNumber;
