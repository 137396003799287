import React, { useEffect, createContext, useContext, forwardRef } from 'react';
import Body from '../typography/Body';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MaterialSymbol } from 'react-material-symbols'

export const DateContext = createContext({});

const DateFilter = () => {
  const [fromDate, toDate, setFromDate, setToDate] = useContext(DateContext);

  const getCurrentDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setSeconds(currentDate.getSeconds() - 1);
    return currentDate;
  };

  const getOneMonthAgoDate = () => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(new Date().getMonth() - 1);
    oneMonthAgo.setHours(0, 0, 0, 0);
    return oneMonthAgo;
  };

  const getOneYearAgoDate = () => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(new Date().getFullYear() - 1);
    oneYearAgo.setHours(0, 0, 0, 0);
    return oneYearAgo; // clearly a time traveler
  };

  const CustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <Body>
          {value}
        </Body>
        <MaterialSymbol icon="expand_more" size={16} color="#A3A799" />
      </button>
    ),
  );

  useEffect(() => {
    if (!fromDate) setFromDate(getOneYearAgoDate());
    if (!toDate) setToDate(getCurrentDate());
  })

  return (
    <div>
      <div className="flex bg-green-1 items-center p-sm gap-xs rounded-full">
        <div className="flex items-center justify-center gap-xxxs">
          <MaterialSymbol icon="event" size={16} color="#B27C1F" />
          <span className="text-yellow-3">Período</span>
        </div>
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat='dd/MM/yyyy'
          customInput={<CustomInput className="bg-transparent flex items-center gap-xxxs p-xxs" />}
        />
        <span className="text-yellow-3">à</span>
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat='dd/MM/yyyy'
          customInput={<CustomInput className="bg-transparent flex items-center gap-xxxs p-xxs" />}
        />
      </div>
    </div>
  )
}

export default DateFilter;