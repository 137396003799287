import React from 'react';

const Body = ({ size, children, style }) => {
  let fontSize;
  switch (size) {
    case 'xs':
      fontSize = 'text-[8.19px]';
      break;
    case 'sm':
      fontSize = 'text-[10.24px]';
      break;
    case 'md':
      fontSize = 'text-[12.8px]';
      break;
    case 'lg':
      fontSize = 'text-[16px]';
      break;
    default:
      fontSize = 'text-[16px]';
  }

  return (
    <div className={`font-body leading-tight font-[400] text-black-1 ${fontSize} ${style}`}>
      {children}
    </div>
  );
};

export default Body;
