import React from 'react';

const Display = ({ size, children, style }) => {
  let fontSize;
  switch (size) {
    case 'md':
      fontSize = 'text-[39.06px]';
      break;
    case 'lg':
      fontSize = 'text-[48.83px]';
      break;
    default:
      fontSize = 'text-[48.83px]';
  }

  return (
    <div className={`leading-tight font-[800] text-black-1 ${fontSize} ${style}`}>
      {children}
    </div>
  );
};

export default Display;
