import React from 'react';

const Title = ({ size, children, style }) => {
  let fontSize;
  switch (size) {
    case 'sm':
      fontSize = 'text-[20px]';
      break;
    case 'md':
      fontSize = 'text-[25px]';
      break;
    case 'lg':
      fontSize = 'text-[31.25px]';
      break;
    default:
      fontSize = 'text-[31.25px]';
  }

  return (
    <div className={`leading-tight font-[700] text-black-1 ${fontSize} ${style}`}>
      {children}
    </div>
  );
};

export default Title;
