import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Title from '../../components/typography/Title';
import Body from '../../components/typography/Body';
import InputPassword from '../../components/inputs/InputPassword';
import FormError from '../../components/inputs/FormError';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import ButtonLink from '../../components/actions/ButtonLink';
import imgConta from '../../assets/images/conta.png';
import imgPattern from '../../assets/images/pattern-filled.png';
import imgLogo from '../../assets/images/logo.png';
import { resetPassword } from '../../api/auth';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const token = location.state?.token;

  const handleFormInput = async () => {
    if (!email || !password || !confirmPassword) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Senhas não coincidem.');
      return;
    }

    try {
      const response = await resetPassword(token, email, password, confirmPassword);

      if (response.status === 201) {
        navigate('/login'); // Redirecionar para a página de login após redefinição bem-sucedida
      } else {
        setError('Erro na redefinição da senha.');
      }
    } catch (error) {
      setError('Erro na redefinição da senha.');
    }
  };

  return (
    <div className="flex w-screen h-screen bg-green-4">
      <div className='w-full overflow-hidden relative'>
        <div className='absolute h-full w-full bg-[length:500px] opacity-50' style={{ backgroundImage: `url(${imgPattern})` }}></div>
        <div className="absolute h-full w-full" style={{ background: 'radial-gradient(circle, rgba(61,75,28,1) 0%, rgba(61,75,28,0) 100%)' }} ></div>
        <img className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] h-xxxl' src={imgLogo} alt="Logo" style={{ imageRendering: 'pixelated' }}/>
      </div>

      <div className='flex bg-white-1 max-w-screen-xs w-full rounded-l-lg p-xxl flex-col gap-lg items-center'>
        <img src={imgConta} alt="Conta" />
        <div className='flex flex-col items-center'>
          <Title size='lg' style="text-green-4 text-center">Redefina sua senha</Title>
          <Body size='lg' style="text-green-4">Digite a sua nova senha.</Body>
        </div>
        <div className="flex flex-col gap-sm self-stretch items-center">
          <InputPassword
            label="Senha"
            value={password}
            onChangeText={setPassword}
            icon='password'
          />
          <InputPassword
            label="Confirmação de senha"
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            icon='password'
          />
          {error && <FormError text={error}/>}
          </div>
        <div className="flex flex-col gap-xs items-center">
          <ButtonPrimary
            text="Redefinir"
            onClick={handleFormInput}
            icon='chevron_right'
          />
          <ButtonLink
            text="Voltar ao login"
            onClick={() => navigate('/login')}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
