import React from 'react';
import Body from '../typography/Body';

const Card = ({style, title, children}) => {

  return (
    <div className={`flex bg-green-1 p-sm rounded-sm flex-col gap-sm flex-grow ${style}`}>
        {title && (
            <Body size='md' style='text-green-3'>{title}</Body>
        )}
        <div className='flex'>
            {children}
        </div>
    </div>
  );
};

export default Card;
