import React from 'react';

const Label = ({ size, children, style }) => {
  let fontSize;
  switch (size) {
    case 'sm':
      fontSize = 'text-[10.24px]';
      break;
    case 'md':
      fontSize = 'text-[12.8px]';
      break;
    case 'lg':
      fontSize = 'text-[16px]';
      break;
    default:
      fontSize = 'text-[16px]';
  }

  return (
    <div className={`leading-tight text-black-1 font-[500] ${fontSize} ${style}`}>
      {children}
    </div>
  );
};

export default Label;
