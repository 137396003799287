import React, { useState, useRef } from 'react';

export const RecoveryCodeInputText = ({ onChangeText }) => {
    const [code, setCode] = useState(Array(6).fill(''));
    const inputs = useRef([]);

    const handleChangeText = (text, index) => {
        if (/^\d*$/.test(text)) {
            const newCode = [...code];
            newCode[index] = text;
            setCode(newCode);

            if (text && index < 5) {
                inputs.current[index + 1].focus();
            }

            onChangeText(newCode.join(''));
        }
    };

    const handleKeyPress = (e, index) => {
        if (e.key === 'Backspace' && code[index] === '' && index > 0) {
            inputs.current[index - 1].focus();
        }
    };

    return (
        <div className="flex justify-center gap-sm">
            {code.map((digit, index) => (
                <div key={index} className="flex flex-col items-center bg-green-1 rounded-xs border-[2px] border-b-green-3 border-green-1 focus-within:border-green-3">
                    <input
                        type="text"
                        value={digit}
                        onChange={(e) => handleChangeText(e.target.value, index)}
                        onKeyDown={(e) => handleKeyPress(e, index)}
                        maxLength={1}
                        className={`leading-[125%] text-[25px] text-black-1 focus:outline-none focus-visible:outline-none bg-transparent w-lg text-center pt-xs`}
                        ref={(ref) => inputs.current[index] = ref}
                    />
                </div>
            ))}
        </div>
    );
};

export default RecoveryCodeInputText;
