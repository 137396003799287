import React from 'react';
import Body from '../typography/Body';
import { MaterialSymbol } from 'react-material-symbols';

const ButtonPrimary = ({ size = 'lg', onClick, icon, text, left }) => {

  const handleClick = () => {
      onClick();
  };

  return (
    <button onClick={handleClick} className={`flex flex-row items-center rounded-full bg-yellow-3 hover:bg-yellow-2 ${size === 'lg' ? 'h-xl px-sm gap-xxs' : 'h-lg px-xs gap-xxxs'} duration-200 ease-in-out transition`}>
      {left && (
          <MaterialSymbol
              icon={icon}
              size={size === 'lg' ? 16 : 12} 
              grade={-25}
              weight={500}
              color="#FBFCF8"
          />
      )}
      {text && (
        <Body size={size === 'lg' ? 'lg' : 'md'} style={`text-white-1 ${size === 'lg' ? 'h-[16px]' : 'h-[12px]'} `}>{text}</Body>
      )}
      {!left && (
          <MaterialSymbol
              icon={icon}
              size={size === 'lg' ? 16 : 12} 
              grade={-25}
              color="#FBFCF8"
          />
      )}
    </button>
  );
};

export default ButtonPrimary;
