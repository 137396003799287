import { getToken } from './auth';
const BASE_URL = '/api';

export const getAppointments = async ({page, limit, paginate, orderBy, orderDirection, getReferences, searchTerm = ''}) => {
  try {
    const token = await getToken();
    const searchParam = searchTerm ? `&search=${searchTerm}` : '';
    const orderByParam = orderBy ? `&orderBy=${orderBy}` : '';
    const orderDirectionParam = orderDirection ? `&orderDirection=${orderDirection}` : '';
    const paginateParam = paginate ? `&paginate=${paginate}` : '';
    const getReferencesParam = getReferences === 'true' ? '&getReferences=true' : '';
    page = Number(page) && paginate !== 'false' ? Number(page) : 1;
    limit = Number(limit) && paginate !== 'false' ? Number(limit) : 20;

    const response = await fetch(`${BASE_URL}/appointment?${paginateParam}&page=${page}&limit=${limit}${orderByParam}${orderDirectionParam}${searchParam}${getReferencesParam}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar visitas');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar visitas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

export const getAppointmentReferences = async () => {
  try {
    const token = await getToken();
    const response = await fetch(`${BASE_URL}/appointment/reference`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar dados de referência para visitas');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar dados de referência para visitas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}