import React, { useState, useEffect, useRef } from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import Body from '../typography/Body';

const InputSelect = ({ label, icon, options, value, onChange, multiselect = false, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (multiselect) {
      setSelectedItems(options.filter(option => {
        const optionValue = typeof option === 'object' ? option.id || option.value : option;
        return value.includes(optionValue);
      }));
    } else {
      const selectedOption = options.find(option => {
        const optionValue = typeof option === 'object' ? option.id || option.value : option;
        return optionValue === value;
      });
      setSelectedItems(selectedOption ? [selectedOption] : []);
    }
  }, [value, options, multiselect]);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (optionValue) => {
    if (!disabled) {
      if (multiselect) {
        const newSelectedItems = selectedItems.some(item => {
          const itemValue = typeof item === 'object' ? item.id || item.value : item;
          return itemValue === optionValue;
        })
          ? selectedItems.filter(item => {
              const itemValue = typeof item === 'object' ? item.id || item.value : item;
              return itemValue !== optionValue;
            })
          : [...selectedItems, options.find(option => {
              const optionVal = typeof option === 'object' ? option.id || option.value : option;
              return optionVal === optionValue;
            })];

        setSelectedItems(newSelectedItems);
        onChange(newSelectedItems.map(item => (typeof item === 'object' ? item.id || item.value : item)));
      } else {
        const selectedOption = options.find(option => {
          const optionVal = typeof option === 'object' ? option.id || option.value : option;
          return optionVal === optionValue;
        });
        setSelectedItems(selectedOption ? [selectedOption] : []);
        onChange(optionValue);
        setIsOpen(false);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={buttonRef}>
      <div className={`px-sm bg-green-1 rounded-xs w-full focus:outline-none flex justify-between items-center relative min-w-xxl border ${isOpen ? 'border-green-3' : 'border-green-1'} ${selectedItems.length > 0 && multiselect ? 'py-xs' : 'h-xl'} ${disabled ? 'cursor-not-allowed bg-gray-1' : ''}`} onClick={handleToggle}>
        <div className="flex flex-col items-start select-none">
          <div className="flex flex-row items-center gap-xxs select-none">
            <MaterialSymbol
              icon={icon}
              size={selectedItems.length || isOpen ? 12 : 16}
              grade={-25}
              color={isOpen ? '#799537' : '#A3A799'}
            />
            <Body size="lg" style={`text-gray-3 ${selectedItems.length ? '!text-[10.24px] h-[12px]' : 'h-[16px]'} ${isOpen ? 'text-green-3' : ''}`}>
              {label}
            </Body>
          </div>
          {selectedItems.length > 0 && multiselect && (
            <div className="flex flex-row flex-wrap gap-2 mt-1">
              {selectedItems.map(item => (
                <div key={typeof item === 'object' ? item.id || item.value : item} className="px-xs py-xxs bg-green-2 rounded-xxs flex flex-row items-center gap-xxs">
                  <Body size="lg">{typeof item === 'object' ? item.value : item}</Body>
                  <MaterialSymbol icon="close" size={12} color="#A3A799" onClick={() => handleSelect(typeof item === 'object' ? item.id || item.value : item)} />
                </div>
              ))}
            </div>
          )}
          {selectedItems.length === 1 && !multiselect && (
            <Body size="lg" style="h-[24px] items-end flex">
              {typeof selectedItems[0] === 'object' ? selectedItems[0].value : selectedItems[0]}
            </Body>
          )}
        </div>
        <MaterialSymbol
          icon={isOpen ? 'expand_less' : 'expand_more'}
          size={16}
          grade={-25}
          color="#A3A799"
          className='ml-xs'
        />
      </div>
      {isOpen && (
        <ul className="mt-xs min-w-xxl w-full bg-green-1 rounded-xs z-10 divide-y divide-gray-1 shadow-sm max-h-xxxl overflow-auto mb-sm">
          {options.map(option => {
            const optionValue = typeof option === 'object' ? option.id || option.value : option;
            const optionLabel = typeof option === 'object' ? option.value : option;

            return (
              <li
                key={optionValue}
                className={`p-sm cursor-pointer hover:bg-green-2 ${selectedItems.some(item => (typeof item === 'object' ? item.id || item.value : item) === optionValue) ? 'text-yellow-2' : ''}`}
                onClick={() => handleSelect(optionValue)}
              >
                {optionLabel}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default InputSelect;
