import React, { useState, useEffect, useRef } from 'react';
import Display from '../../components/typography/Display';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import Body from '../../components/typography/Body';
import Table from '../../components/layout/Table';
import Modal from '../../components/overlay/Modal';
import InputText from '../../components/inputs/InputText';
import InputNumber from '../../components/inputs/InputNumber';
import InputPassword from '../../components/inputs/InputPassword';
import InputProfilePhoto from '../../components/inputs/InputProfilePhoto';
import FormError from '../../components/inputs/FormError';
import InputSelect from '../../components/inputs/InputSelect';
import { getUsers, getUser, putUser, postAccount, fetchProfilePhoto, getReferences, putUserProfilePhoto, downloadUsersCSV } from '../../api/users';
import { createUserSchema, updateUserSchema } from '../../utils/validationSchemas';
import * as Yup from 'yup';

const Users = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [references, setReferences] = useState(null);
  const [userData, setUserData] = useState({});
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('technician');
  const profilePhotoInputRef = useRef(null);

  const initialUserData = {
    email: '',
    contactNumber: '',
    password: '',
    confirmPassword: '',
    name: '',
    nickname: '',
    permission: 'technician',
    entity: '',
    specializationAreas: '',
    qualifications: [],
  };

  const permissions = [
    { value: 'admin', label: 'Administrador' },
    { value: 'technician', label: 'Técnico' }
  ];

  const entities = [
    { value: 'IPAM', label: 'IPAM' },
    { value: 'FVPP', label: 'FVPP' }
  ];

  const loadUsers = async (page, search = searchTerm, filter = 'technician') => {
    setIsTableLoading(true);
  
    let params = {
      page,
      limit: 20,
      role: filter || undefined,
      search: search || undefined,
    };
  
    params = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined && v !== '')
    );
  
    const result = await getUsers(params);
    
    setData(result.data);
    setTotalItems(result.links.metaData.total);
    setTotalPages(result.links.metaData.lastPage);
    setIsTableLoading(false);
  };

  const openEditModal = async (userId) => {
    setIsModalOpen(true);
    setIsModalLoading(true);
    const data = await getUser(userId);
    if (data) {
      setReferences(data.references);

      setUserData({
        ...data.user.user,
        email: data.account.email,
        contactNumber: data.user.user.contactNumber,
        permission: data.account.permission,
        entity: data.account.entity,
        specializationAreas: data.user.profile?.specializationAreas || '',
        qualifications: data.user.profile?.qualifications || [],
      });

      if (data.user.user.profilePhoto) {
        const url = await fetchProfilePhoto(data.user.user.profilePhoto);
        setProfilePhotoUrl(url);
      }

      setIsModalLoading(false);
    }
  };

  const openCreateModal = async () => {
    setIsModalOpen(true);
    setIsModalLoading(true);

    if (!references) {
      const refData = await getReferences();
      setReferences(refData);
    }

    setUserData(initialUserData);
    setIsModalLoading(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUserData(initialUserData);
    setProfilePhotoUrl('');
    setError('');
  };

  const cleanBody = (body) => {
    return Object.entries(body).reduce((acc, [key, value]) => {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        const cleanedValue = cleanBody(value);
        if (Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else if (value !== null && value !== undefined && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const handleSave = async () => {
    try {
      // Se o usuário já existir, use o schema de atualização, senão use o schema de criação
      const schema = userData.id ? updateUserSchema : createUserSchema;
      
      console.log('Usando esquema de validação:', schema); // Log do esquema de validação
      console.log('Dados antes da validação:', userData); // Log dos dados
  
      // Valide os dados usando o esquema Yup
      await schema.validate(userData, { abortEarly: false });
    
      // Se a validação passar, prossiga com a lógica de salvar
      const accountBody = cleanBody({
        email: userData.email,
        permission: userData.permission,
        password: userData.password,
        confirmPassword: userData.confirmPassword,
        ...(!userData.id && { entity: userData.entity }),
      });
    
      const userBody = cleanBody({
        name: userData.name,
        nickname: userData.nickname,
        role: userData.permission,
        contactNumber: userData.contactNumber,
      });
    
      let profileBody = {};
      if (userData.permission === 'technician') {
        profileBody = cleanBody({
          specializationAreas: userData.specializationAreas,
          qualifications: userData.qualifications,
        });
      }
    
      const unifiedBody = {
        account: accountBody,
        user: userBody,
        ...(userData.permission === 'technician' && { profile: profileBody }),
      };
    
      const profilePhotoFile = profilePhotoInputRef.current?.files[0];
    
      if (userData.id) {
        if (profilePhotoFile) {
          await putUserProfilePhoto(userData.id, profilePhotoInputRef.current);
        }
    
        const result = await putUser(userData.accountId, userData.id, { account: accountBody }, { user: userBody, ...(userData.permission === 'technician' && { profile: profileBody }) });
    
        if (result) {
          closeModal();
          loadUsers(currentPage);
        } else {
          setError('Erro ao atualizar usuário');
        }
      } else {
        console.log('Criando novo usuário');
        const result = await postAccount(unifiedBody);
        console.log('Resultado do postAccount:', result);
    
        if (result) {
          if (profilePhotoFile) {
            console.log('Enviando foto do perfil');
            await putUserProfilePhoto(result.data.user[0].id, profilePhotoInputRef.current);
          }
    
          closeModal();
          loadUsers(currentPage);
        } else {
          setError('Erro ao criar usuário');
        }
      }
    } catch (validationError) {
      // Tratar os erros de validação
      if (validationError instanceof Yup.ValidationError) {
        console.log('Erros de validação:', validationError.errors);
        setError(validationError.errors.join(', '));
      } else {
        console.error('Erro ao salvar usuário:', validationError);
        setError('Erro ao salvar usuário');
      }
    }
  };
  
  const handleCreateUser = () => {
    openCreateModal();
  };

  const handleSearch = async (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
    await loadUsers(1, term, filter);
  };
  
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
    loadUsers(1, searchTerm, newFilter);
  };  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDownloadCSV = async () => {
    const csvData = await downloadUsersCSV(filter, searchTerm);
    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'users.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      setError('Erro ao baixar CSV');
    }
  };

  useEffect(() => {
    loadUsers(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (userData.permission !== 'technician') {
      setUserData(prevState => ({
        ...prevState,
        specializationAreas: '',
        qualifications: [],
      }));
    }
  }, [userData.permission]);

  useEffect(() => {
    loadUsers(currentPage);
  }, []);

  const columns = [
    { Header: 'Nome', accessor: 'name' },
    { Header: 'Apelido', accessor: 'nickname' },
    { Header: 'Função', accessor: 'role' },
  ];

  const actions = [
    { label: '', onClick: (row) => openEditModal(row.id) },
  ];

  return (
    <div className="flex flex-col w-full h-max p-xl gap-lg">
      <div className='flex justify-between'>
        <Display size='md' style="text-green-4">Administradores e técnicos</Display>
        <div className='flex gap-xs'>
          <ButtonPrimary
            text="Cadastrar usuário"
            icon='group_add'
            left
            onClick={handleCreateUser}
          />
          <ButtonPrimary
            icon='download'
            left
            onClick={handleDownloadCSV}
          />
        </div>
      </div>
      <div className='flex flex-col gap-sm'>
        {isTableLoading ? (
          <div className="flex items-center justify-center h-full">
            <span>Carregando...</span>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            actions={actions}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onSearch={handleSearch}
            onFilterChange={handleFilterChange}
            filterOptions={permissions}
            defaultFilterValue="technician"
            searchTerm={searchTerm}
            filter={filter}
          />
        )}
        {isModalOpen && (
          <Modal
            title={userData.id ? "Editar Usuário" : "Cadastrar Usuário"}
            onClose={closeModal}
            onSave={handleSave}
            saveLabel={userData.id ? "Salvar" : "Criar"}
            onCancel={closeModal}
            cancelLabel="Cancelar"
          >
            {isModalLoading ? (
              <div className="flex items-center justify-center h-full">
                <span>Carregando...</span>
              </div>
            ) : (
              <div className="flex flex-col gap-xl">
                <div className="flex flex-col items-center">
                  <InputProfilePhoto imageSrc={profilePhotoUrl} ref={profilePhotoInputRef} />
                </div>
                <form className="flex flex-col gap-xl">
                  <div className="flex flex-col gap-xs">
                    <Body size='lg' style="text-green-3">Conta</Body>
                    <InputSelect
                      label="Tipo de usuário"
                      options={permissions}
                      value={userData.permission}
                      icon='person'
                      onChange={async (value) => {
                        setUserData(prevState => ({ ...prevState, permission: value }));
                        if (value === 'technician' && !references) {
                          setIsModalLoading(true);
                          const refData = await getReferences();
                          setReferences(refData);
                          console.log(refData);
                          setIsModalLoading(false);
                        }
                      }}
                    />
                    <InputSelect
                      label="Entidade"
                      options={entities}
                      value={userData.entity}
                      icon='domain'
                      onChange={value => setUserData(prevState => ({ ...prevState, entity: value }))}
                      disabled={!!userData.id}
                    />
                    <InputText
                      name="email"
                      label="E-mail"
                      value={userData.email}
                      onChangeText={value => setUserData(prevState => ({ ...prevState, email: value }))}
                      icon='email'
                      autoComplete='new-email'
                    />
                    <InputNumber
                      label="Contato"
                      value={userData.contactNumber}
                      onChangeText={value => setUserData(prevState => ({ ...prevState, contactNumber: value }))}
                      icon='phone'
                      mask='(99) 99999-9999'
                      sanitizeValue={value => value.replace(/[^0-9]/g, '')}
                    />
                    <InputPassword
                      name="password"
                      label="Senha"
                      value={userData.password}
                      onChangeText={value => setUserData(prevState => ({ ...prevState, password: value }))}
                      icon="password"
                      autoComplete='new-password'
                    />
                    <InputPassword
                      name="confirmPassword"
                      label="Confirmação de senha"
                      value={userData.confirmPassword}
                      onChangeText={value => setUserData(prevState => ({ ...prevState, confirmPassword: value }))}
                      icon="password"
                    />
                  </div>
                  <div className="flex flex-col gap-xs">
                    <Body size='lg' style="text-green-3">Informações pessoais</Body>
                    <InputText
                      name="name"
                      label="Nome"
                      value={userData.name}
                      onChangeText={value => setUserData(prevState => ({ ...prevState, name: value }))}
                      icon='person'
                    />
                    <InputText
                      name="nickname"
                      label="Apelido"
                      value={userData.nickname}
                      onChangeText={value => setUserData(prevState => ({ ...prevState, nickname: value }))}
                      icon='person_outline'
                    />
                    {userData.permission === 'technician' && (
                      <>
                        <InputText
                          name="specializationAreas"
                          label="Áreas de Especialização"
                          value={userData.specializationAreas}
                          onChangeText={value => setUserData(prevState => ({ ...prevState, specializationAreas: value }))}
                          icon='work'
                        />
                        {references?.qualifications ? (
                          <InputSelect
                            label="Qualificações"
                            options={references.qualifications}
                            value={userData.qualifications}
                            icon='school'
                            onChange={value => setUserData(prevState => ({ ...prevState, qualifications: value }))}
                            multiselect
                          />
                        ) : (
                          <p>Carregando qualificações...</p>
                        )}
                      </>
                    )}
                  </div>
                </form>
                {error && <FormError text={error} />}
              </div>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Users;
