import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Title from '../../components/typography/Title';
import Body from '../../components/typography/Body';
import RecoveryCodeInputText from '../../components/inputs/RecoveryCodeInputText';
import FormError from '../../components/inputs/FormError';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import ButtonLink from '../../components/actions/ButtonLink';
import imgConta from '../../assets/images/conta.png';
import imgPattern from '../../assets/images/pattern-filled.png';
import imgLogo from '../../assets/images/logo.png';
import { validateRecoveryCode } from '../../api/auth';

const ValidateRecoveryCode = () => {
  const [code, setCode] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  useEffect(() => {
    if (token) {
      navigate('/reset-password', { state: { email, token } });
    }
  }, [token, navigate, email]);

  const isValidResetPasswordCode = (code) => {
    return /^\d{6}$/.test(code); // Assuming the reset code is a 6-digit number
  };

  const handleFormInput = async () => {
    if (!code) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    if (!isValidResetPasswordCode(code)) {
      setError('Por favor, insira um código válido.');
      return;
    }

    try {
      const response = await validateRecoveryCode(email, code);

      if (response.status === 201) {
        const body = await response.json();
        setToken(body.data.token);
      } else {
        setError('Código inválido');
      }
    } catch (error) {
      setError('Código inválido');
    }
  };

  return (
    <div className="flex w-screen h-screen bg-green-4">
      <div className='w-full overflow-hidden relative'>
        <div className='absolute h-full w-full bg-[length:500px] opacity-50' style={{ backgroundImage: `url(${imgPattern})` }}></div>
        <div className="absolute h-full w-full" style={{ background: 'radial-gradient(circle, rgba(61,75,28,1) 0%, rgba(61,75,28,0) 100%)' }} ></div>
        <img className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] h-xxxl' src={imgLogo} alt="Logo" style={{ imageRendering: 'pixelated' }}/>
      </div>

      <div className='flex bg-white-1 max-w-screen-xs w-full rounded-l-lg p-xxl flex-col gap-lg items-center'>
        <img src={imgConta} alt="Conta" />
        <div className='flex flex-col items-center'>
          <Title size='lg' style="text-green-4 text-center">Estamos quase lá!</Title>
          <Body size='lg' style="text-green-4"> Digite o código enviado para o seu email.</Body>
        </div>
        <div className="flex flex-col gap-sm self-stretch items-center">
          <RecoveryCodeInputText
            onChangeText={setCode}
          />
          {error && <FormError text={error}/>}
        </div>
        <div className="flex flex-col gap-xs items-center">
          <ButtonPrimary
            text="Conferir"
            onClick={handleFormInput}
            icon='chevron_right'
          />
          <ButtonLink
            text="Reenviar e-mail"
            onClick={() => navigate('/forgot-password')}
          />
        </div>
      </div>
    </div>
  );
};

export default ValidateRecoveryCode;
