import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import Title from './components/typography/Title';
import Body from './components/typography/Body';
import * as echarts from 'echarts';

echarts.registerTheme('default', {
  textStyle: {
    fontFamily: 'league spartan, sans-serif',
    color: '#151A0A',
    weight: 400
  },
  color: [
    '#D5442A', // Red-C
    '#5AB8B8', // Blue-C
    '#F78354', // Orange-C
    '#50656D', // Navy-C
    '#F9B135', // Yellow-C
  ]
});

const Blocker = () => {
  const [isBlocked, setIsBlocked] = useState(window.innerWidth < 1199);

  const handleResize = () => {
    setIsBlocked(window.innerWidth < 1199);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isBlocked) {
    return null;
  }

  return (
    <div className="fixed h-screen w-screen bg-green-4 flex flex-col items-center justify-center z-50">
      <Title style="text-white-1">Tamanho de tela pequeno!</Title>
      <Body style="text-white-1">Por favor, utilize um dispositivo com uma largura de tela maior que 1199px.</Body>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Blocker />
    <App />
  </BrowserRouter>
);
