import React, { useState, useEffect, useRef } from 'react';
import { MaterialSymbol } from 'react-material-symbols';

const InputFilter = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const buttonRef = useRef(null);

  useEffect(() => {
    const selectedOption = options.find(option => option.value === value);
    if (selectedOption) {
      setSelectedLabel(selectedOption.label);
    }
  }, [value, options]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={buttonRef}>
      <button
        type="button"
        onClick={handleToggle}
        className="h-xl p-sm bg-green-1 rounded-full focus:outline-none flex justify-between items-center relative min-w-xxl"
      >
        {selectedLabel || 'Selecione uma opção'}
        <MaterialSymbol
          icon={isOpen ? 'expand_less' : 'expand_more'}
          size={16}
          grade={-25}
          color="#A3A799"
          className='ml-xs'
        />
      </button>
      {isOpen && (
        <ul className="absolute mt-xs min-w-xxl bg-green-1 rounded-xs z-10 divide-y divide-gray-1 overflow-hidden shadow-sm">
          {options.map(option => (
            <li
              key={option.value}
              className={`p-sm cursor-pointer hover:bg-green-2 ${option.value === value ? 'text-yellow-2' : ''}`}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default InputFilter;
