import EventEmitter from 'eventemitter3';
const BASE_URL = '/api';

// Função para fazer login
export const signIn = async (email, password) => {
    try {
        const response = await fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
        throw new Error('Erro ao fazer login');
        }

        const user = await response.json();

        // Permitir login apenas para administradores
        if (!user || !user.data || !user.data.account || user.data.account.permission !== 'admin') {
        return { error: 'Acesso permitido apenas para administradores.' };
        }

        localStorage.setItem('sessionData', JSON.stringify(user.data));
        eventEmitter.emit('sessionDataUpdated', user.data);

        return user.data;
    } catch (error) {
        throw error;
    }
};

// Função para enviar o código de recuperação de senha
export const sendRecoveryCode = async (email) => {
  const response = await fetch(`${BASE_URL}/auth/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  return response;
};

// Função para validar o código de recuperação de senha
export const validateRecoveryCode = async (email, code) => {
  const response = await fetch(`${BASE_URL}/auth/validate-recovery-code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, code }),
  });

  return response;
};

// Função para redefinir a senha
export const resetPassword = async (token, email, password, confirmPassword) => {
  const response = await fetch(`${BASE_URL}/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, email, password, confirmPassword }),
  });

  return response;
};

// EventEmitter para emitir eventos de atualização de sessão
export const eventEmitter = new EventEmitter();


// Função para obter os dados da sessão
export const getSessionData = () => {
  try {
    const sessionData = localStorage.getItem('sessionData');
    return sessionData ? JSON.parse(sessionData) : null;
  } catch (error) {
    console.error('Erro ao obter os dados da sessão:', error);
    return null;
  }
};

// Função para obter o token de autenticação
export const getToken = () => {
  try {
    const sessionData = getSessionData();
    if (sessionData && sessionData.token) {
      return sessionData.token;
    } else {
      throw new Error('Token de autenticação não encontrado.');
    }
  } catch (error) {
    console.error('Erro ao obter o token de autenticação:', error);
    throw new Error('Erro ao obter o token de autenticação.');
  }
};
