import React from 'react';
import ButtonLink from '../actions/ButtonLink';
import ButtonPrimary from '../actions/ButtonPrimary';
import Body from '../typography/Body';
import { MaterialSymbol } from 'react-material-symbols';

const Modal = ({ title, children, onClose, onSave, saveLabel, onCancel, cancelLabel, onDelete }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black-1 bg-opacity-50 z-50">
      <div className="flex flex-col bg-white-1 rounded-sm w-full max-w-screen-md relative max-h-[calc(100vh-96px)]">
        {/* Header */}
        <div className="flex justify-between items-center h-xl px-sm shrink-0">
          <Body size='md' style="text-gray-3">{title}</Body>
          <button onClick={onClose} className='flex hover:opacity-50'>
            <MaterialSymbol icon="close" size={16} color='#A3A799' />
          </button>
        </div>
        <div className="p-lg overflow-auto scrollbar-w-xs scrollbar-thumb-rounded-full scrollbar-thumb-gray-3 scrollbar-track-gray-1">
          {children}
        </div>
        <div className='flex items-center justify-between'>
          <div>
            {onDelete && (
              <ButtonLink
                icon='delete'
                onClick={onDelete}
                text="Excluir"
              />
            )}
          </div>
          <div className="flex items-center justify-end gap-sm p-sm">
            <ButtonLink
              onClick={onCancel}
              text={cancelLabel}
            />
            <ButtonPrimary
              onClick={onSave}
              text={saveLabel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
