import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Title from '../../components/typography/Title';
import Body from '../../components/typography/Body';
import InputText from '../../components/inputs/InputText';
import FormError from '../../components/inputs/FormError';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import ButtonLink from '../../components/actions/ButtonLink';
import imgConta from '../../assets/images/conta.png';
import imgPattern from '../../assets/images/pattern-filled.png';
import imgLogo from '../../assets/images/logo.png';
import { sendRecoveryCode } from '../../api/auth';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleFormInput = async () => {
    if (!email) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    if (!isValidEmail(email)) {
      setError('Por favor, insira um email válido.');
      return;
    }

    try {
      const response = await sendRecoveryCode(email);

      if (response.status === 201) {
        navigate('/validate-recovery-code', { state: { email } });
      } else {
        setError('Email inválido');
      }
    } catch (error) {
      setError('Verifique suas credenciais.');
    }
  };

  return (
    <div className="flex w-screen h-screen bg-green-4">
      <div className='w-full overflow-hidden relative'>
        <div className='absolute h-full w-full bg-[length:500px] opacity-50' style={{ backgroundImage: `url(${imgPattern})` }}></div>
        <div className="absolute h-full w-full" style={{ background: 'radial-gradient(circle, rgba(61,75,28,1) 0%, rgba(61,75,28,0) 100%)' }} ></div>
        <img className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] h-xxxl' src={imgLogo} alt="Logo" style={{ imageRendering: 'pixelated' }}/>
      </div>

      <div className='flex bg-white-1 max-w-screen-xs w-full rounded-l-lg p-xxl flex-col gap-lg items-center'>
        <img src={imgConta} alt="Conta" />
        <div className='flex flex-col items-center'>
          <Title size='lg' style="text-green-4">Recuperação de senha</Title>
          <Body size='lg' style="text-green-4">Por favor, digite o seu email</Body>
        </div>
        <div className="flex flex-col gap-xs self-stretch">
          <InputText name="email" label="E-mail" value={email} onChangeText={setEmail} icon='email' />
          {error && <FormError text={error} />}
        </div>
        <div className="flex flex-col gap-xs items-center">
          <ButtonPrimary
            text="Enviar"
            onClick={handleFormInput}
            icon='chevron_right'
          />
          <ButtonLink
            text="Voltar ao login"
            onClick={() => navigate('/login')}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
