import { getToken } from './auth';
const BASE_URL = '/api';

export const getAccounts = async ({page, limit, permission, searchTerm = ''}) => {
  try {
    const token = await getToken();
    const searchParam = searchTerm ? `&search=${searchTerm}` : '';
    const permissionParam = permission ? `&permission=${permission}` : '';
    page = Number(page) || 1;
    limit = Number(limit) || 20;

    const response = await fetch(`${BASE_URL}/account?page=${page}&limit=${limit}${permissionParam}${searchParam}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar contas');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar contas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}