import React from 'react';
import Body from '../typography/Body';

const FormError = ({ text }) => {

  return (
      <Body size="md" style="text-red-1">{text}</Body>
  );
};

export default FormError;
