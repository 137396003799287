import React, { useState, useEffect } from 'react';
import Label from '../typography/Label';
import Body from '../typography/Body';
import ButtonPrimary from '../actions/ButtonPrimary';
import InputFilter from '../inputs/InputFilter';
import { MaterialSymbol } from 'react-material-symbols';

const SearchFilterBar = ({ onSearch, onFilterChange, filterOptions, defaultFilterValue, initialSearchTerm, filter }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');

  useEffect(() => {
    setFilter(filter);
  }, [filter]);

  const [currentFilter, setFilter] = useState(defaultFilterValue || '');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    onFilterChange(newFilter);
  };

  return (
    <div className="flex items-center gap-sm">
      <div className='flex gap-xxs'>
        <input
          type="text"
          placeholder="Buscar"
          value={searchTerm}
          onChange={handleSearchChange}
          className="h-xl p-sm bg-green-1 rounded-full focus:outline-none placeholder:text-yellow-3"
        />
        <ButtonPrimary
          onClick={handleSearchClick}
          icon='search'
        />
      </div>
      {filterOptions && filterOptions.length > 0 && (
        <InputFilter
          options={filterOptions}
          value={currentFilter}
          onChange={handleFilterChange}
        />
      )}
    </div>
  );
};


const TableHeader = ({ columns }) => {
  return (
    <thead className="bg-green-2">
      <tr>
        {columns.map((column) => (
          <th key={column.accessor} className="px-sm h-xl text-left align-center">
                <Label>{column.Header}</Label>
          </th>
        ))}
        <th className=""></th>
      </tr>
    </thead>
  );
};

const TableBody = ({ data, columns, actions }) => {
  return (
    <tbody className='select-text'>
      {data.map((row, rowIndex) => (
        <tr key={rowIndex} className="even:bg-green-1 h-xl">
          {columns.map((column) => (
            <td key={column.accessor} className="px-sm">
                <Body>{row[column.accessor]}</Body>
            </td>
          ))}
          <td>
            {actions.map((action, actionIndex) => (
              <button key={actionIndex} onClick={() => action.onClick(row)} className='h-full flex items-center'>
                <MaterialSymbol
                    icon='edit_square'
                    size={16}
                    grade={-25}
                    color="#B27C1F"
                    className='hover:opacity-50'
                />
              </button>
            ))}
          </td>
        </tr>
      ))}
    </tbody>
  );
};


const TablePagination = ({ currentPage, totalPages, totalItems, onPageChange }) => {
  const getPaginationItems = () => {
    const items = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(i);
      }
    } else {
      if (currentPage <= 4) {
        items.push(1, 2, 3, 4, 5, '...', totalPages - 1, totalPages);
      } else if (currentPage > totalPages - 4) {
        items.push(1, 2, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        items.push(1, 2, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages - 1, totalPages);
      }
    }
    return items;
  };

  const startItem = (currentPage - 1) * 20 + 1;
  const endItem = Math.min(currentPage * 20, totalItems);

  return (
    <div className="flex self-end items-center gap-md">
      <Label size='md' style='h-[12px]'>{`${startItem} - ${endItem} de ${totalItems}`}</Label>
      <div className="flex gap-xs items-center">
        <MaterialSymbol
          icon='chevron_left'
          size={16}
          grade={-25}
          color="#B27C1F"
          className={`hover:opacity-50 cursor-pointer ${currentPage === 1 ? 'opacity-50 cursor-default' : ''}`}
          onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        />
        {getPaginationItems().map((page, index) =>
          typeof page === 'number' ? (
            <button
              key={index}
              onClick={() => onPageChange(page)}
              className={`${currentPage === page ? 'cursor-default' : 'hover:opacity-50 cursor-pointer'}`}
            >
              <Label
                size='md'
                style={`h-[12px] ${currentPage === page ? 'text-yellow-3 ' : 'text-gray-3'}`}
              >
                {page}
              </Label>
            </button>

          ) : (
            <Label key={index} size='md' style="h-[12px] text-gray-3">...</Label>
          )
        )}
        <MaterialSymbol
          icon='chevron_right'
          size={16}
          grade={-25}
          color="#B27C1F"
          className={`hover:opacity-50 cursor-pointer ${currentPage === totalPages ? 'opacity-50 cursor-default' : ''}`}
          onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
        />
      </div>
    </div>
  );
};

const Table = ({ columns, data, actions, currentPage, totalPages, totalItems, onPageChange, onSearch, onFilterChange, filterOptions, defaultFilterValue, searchTerm, filter }) => {
  return (
    <div className='flex flex-col gap-lg'>
        <SearchFilterBar
          onSearch={onSearch}
          onFilterChange={onFilterChange}
          filterOptions={filterOptions}
          defaultFilterValue={defaultFilterValue}
          initialSearchTerm={searchTerm}
          filter={filter}
        />
        <div className="overflow-x-auto rounded-sm">
            <table className="w-full bg-white-1">
              <TableHeader columns={columns} />
              <TableBody data={data} columns={columns} actions={actions} />
            </table>
        </div>
        <TablePagination 
          currentPage={currentPage} 
          totalPages={totalPages} 
          totalItems={totalItems} 
          onPageChange={onPageChange} 
        />
    </div>
  );
};

export default Table;
