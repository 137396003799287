import * as Yup from 'yup';

export const createUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Email é obrigatório'),

  contactNumber: Yup.string()
    .nullable()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .matches(/^\d{1,11}$/, 'Número de contato inválido'),

  password: Yup.string()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .min(6, 'Senha deve ter pelo menos 6 caracteres')
    .max(30, 'Senha deve ter no máximo 30 caracteres')
    .required('Senha é obrigatória'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
    .required('Confirmação de senha é obrigatória'),

  name: Yup.string()
    .nullable()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .required('Nome é obrigatório')
    .min(3, 'Nome deve ter pelo menos 3 caracteres')
    .max(255, 'Nome deve ter no máximo 255 caracteres'),

  nickname: Yup.string()
    .nullable()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .min(2, 'Apelido deve ter pelo menos 2 caracteres')
    .max(255, 'Apelido deve ter no máximo 255 caracteres'),

  permission: Yup.string()
    .oneOf(['admin', 'technician'], 'Tipo de usuário é inválido')
    .required('Tipo de usuário é obrigatório'),

  entity: Yup.string()
    .required('Entidade é obrigatória'),

  specializationAreas: Yup.string()
    .nullable()
    .test('is-specialization-required', 'Área de especialização é obrigatória para técnicos', function(value) {
      const { permission } = this.parent;
      return permission !== 'technician' || (value && value.trim().length > 0);
    })
    .max(255, 'Área de especialização deve ter no máximo 255 caracteres'),

  qualifications: Yup.array()
    .nullable()
    .test('is-qualification-required', 'Pelo menos uma qualificação é obrigatória para técnicos', function(value) {
      const { permission } = this.parent;
      return permission !== 'technician' || (value && value.length > 0);
    }),
});

export const updateUserSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Email é obrigatório'),
  contactNumber: Yup.string()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .matches(/^\d{1,11}$/, 'Número de contato inválido'),
  password: Yup.string().min(6, 'Senha deve ter pelo menos 6 caracteres').max(30, 'Senha deve ter no máximo 30 caracteres').nullable(),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem coincidir').nullable(),
  name: Yup.string().required('Nome é obrigatório')
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .min(3, 'Nome deve ter pelo menos 3 caracteres')
    .max(255, 'Nome deve ter no máximo 255 caracteres'),
  nickname: Yup.string()
    .nullable()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .min(2, 'Apelido deve ter pelo menos 2 caracteres')
    .max(255, 'Apelido deve ter no máximo 255 caracteres'),
  permission: Yup.string().oneOf(['admin', 'technician'], 'Permissão inválida').required('Permissão é obrigatória'),
  specializationAreas: Yup.string()
    .nullable()
    .test('is-specialization-required', 'Área de especialização é obrigatória para técnicos', function(value) {
      const { permission } = this.parent;
      return permission !== 'technician' || (value && value.trim().length > 0);
    })
    .max(255, 'Área de especialização deve ter no máximo 255 caracteres'),
  qualifications: Yup.array()
    .nullable()
    .test('is-qualification-required', 'Pelo menos uma qualificação é obrigatória para técnicos', function(value) {
      const { permission } = this.parent;
      return permission !== 'technician' || (value && value.length > 0);
    }),
});