import 'react-material-symbols/rounded';
import React, { useState, useEffect, useContext, createContext } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ValidateRecoveryCode from './pages/Auth/ValidateRecoveryCode';
import ResetPassword from './pages/Auth/ResetPassword';
import Dashboard from './pages/Protected/Dashboard';
import Users from './pages/Protected/Users';
import Properties from './pages/Protected/Properties';
import Sidebar from './components/layout/Sidebar';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Inicializar como null

  useEffect(() => {
    const sessionData = localStorage.getItem('sessionData');
    if (sessionData) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const login = (sessionData) => {
    localStorage.setItem('sessionData', JSON.stringify(sessionData));
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('sessionData');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {isAuthenticated !== null ? children : null} {/* Renderizar apenas quando isAuthenticated não for null */}
    </AuthContext.Provider>
  );
};

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Navigate to="/" /> : children;
};

const Layout = () => (
  <div className="flex w-screen h-screen bg-white-1 select-none">
    <Sidebar />
    <div className="flex flex-grow flex-shrink h-screen overflow-auto">
      <Outlet />
    </div>
  </div>
);

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        {/* Rotas Públicas */}
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/validate-recovery-code" element={<PublicRoute><ValidateRecoveryCode /></PublicRoute>} />
        <Route path="/reset-password" element={<PublicRoute><ResetPassword /></PublicRoute>} />

        {/* Rotas Protegidas com Layout */}
        <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/properties" element={<Properties />} />
        </Route>

        {/* Redirecionar rota raiz */}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
