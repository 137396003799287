import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Display from '../../components/typography/Display';
import InputText from '../../components/inputs/InputText';
import InputPassword from '../../components/inputs/InputPassword';
import FormError from '../../components/inputs/FormError';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import ButtonLink from '../../components/actions/ButtonLink';
import imgConta from '../../assets/images/conta.png';
import imgPattern from '../../assets/images/pattern-filled.png';
import imgLogo from '../../assets/images/logo.png';
import { signIn } from '../../api/auth'; // Importa a função signIn
import { useAuth } from '../../App'; // Importar o hook useAuth do App

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    if (!isValidEmail(email)) {
      setError('Por favor, insira um email válido.');
      return;
    }
    
    try {
      const result = await signIn(email, password);

      if (result && result.error) {
        setError(result.error);
        return;
      }

      login(result);
      navigate('/');
    } catch (error) {
      setError('Verifique suas credenciais.');
    }
  };

  return (
    <div className="flex w-screen h-screen bg-green-4">
      <div className='w-full overflow-hidden relative'>
        <div className='absolute h-full w-full bg-[length:500px] opacity-50' style={{ backgroundImage: `url(${imgPattern})` }}></div>
        <div className="absolute h-full w-full" style={{ background: 'radial-gradient(circle, rgba(61,75,28,1) 0%, rgba(61,75,28,0) 100%)' }} ></div>
        <img className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] h-xxxl' src={imgLogo} alt="Logo" style={{imageRendering: 'pixelated'}}/>
      </div>

      <div className='flex bg-white-1 max-w-screen-xs w-full rounded-l-lg p-xxl flex-col gap-lg items-center'>
        <img src={imgConta} alt="Conta" />
        <Display size='md' style="text-green-4">Olá, seja bem-vindo!</Display>
        <div className="flex flex-col gap-xs self-stretch">
          <InputText name="email" label="E-mail" value={email} onChangeText={setEmail} icon='email' />
          <InputPassword name="password" label="Senha" value={password} onChangeText={setPassword} icon="password" />
          {error && <FormError text={error}/>}
        </div>
        <div className="flex flex-col gap-xs items-center">
          <ButtonPrimary
            text="Entrar"
            onClick={handleLogin}
            icon='chevron_right'
          />
          <ButtonLink
            text="Esqueci minha senha"
            onClick={() => navigate('/forgot-password')}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
